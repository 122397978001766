.event-landing {
	min-height: 100vh;
}

.event-main {
	margin-top: 10rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 5rem;
	/* gap: 15rem; */
}
.event-main-right {
	text-align: center;
}
.event-main-left h1 {
	font-size: 4.8rem;
	margin-left: 4rem;
}

.event-desc {
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 2.2rem;
	opacity: 0.7;
	margin-top: 4rem;
}
.noborder {
	border-right: 1px solid #9e418c;
}
.nob {
	border-right: none;
}
/* Carousel */
.carousel {
	max-width: fit-content;
	margin: 10rem 0rem;
}
.carousel-img {
	width: 40rem;
	margin: 0 auto;
}
@media screen and (max-width: 1050px) {
	.event-main {
		margin-top: 10rem;
		display: grid;
		grid-template-columns: 1fr;
		gap: 5rem;
		/* gap: 15rem; */
	}
}
@media screen and (max-width: 768px) {
	.noborder {
		border-right: none;
	}
}
@media screen and (max-width: 555px) {
	.event-main {
		margin-top: 10rem;
		display: grid;
		grid-template-columns: 1fr;
		gap: 5rem;
		/* gap: 15rem; */
	}
	.event-main-left h1 {
		font-size: 4rem;
		margin-left: 4rem;
	}
	#noborder {
		border-right: none;
	}
	.event-desc {
		font-size: 1.4rem;
		font-weight: 400;
		line-height: 2.2rem;
		opacity: 0.7;
		margin-top: 4rem;
	}
}
@media screen and (max-width: 450px) {
	.number {
		margin: 2.5rem 0rem;
	}
	.number-no {
		font-size: 2.5rem;
		font-weight: 600;
	}
	.number-text {
		font-size: 1.2rem;
		font-weight: 300;
	}
}
