@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap);
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
/*custom scrollbar*/

::-webkit-scrollbar {
	width: 0.4em;
}

::-webkit-scrollbar-track {
	background: #0c1014;
}

::-webkit-scrollbar-thumb {
	background: #3c45a8;
	border-radius: 0.5em;
}

::-webkit-scrollbar-thumb:hover {
	background: #2f3683;
}

@supports (scrollbar-color: red blue) {
	* {
		scrollbar-color: #5865f2 #0c1014;
		scrollbar-width: thick;
	}
}

/*custom scrollbar*/
html {
	font-size: 62.5%;
	scroll-behavior: smooth;
}
body {
	background: rgb(2, 3, 4);
	background: linear-gradient(
		0deg,
		rgba(2, 3, 4, 1) 0%,
		rgba(24, 34, 48, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020304",endColorstr="#182230",GradientType=1);
	color: #ffffff;
	min-height: 100vh;
	font-family: "Manrope", sans-serif;
	max-width: 80vw;
	margin: 0 auto;
	overflow-x: hidden;
}
.gradient-text {
	background: linear-gradient(
		262.63deg,
		#5656fb -9.37%,
		#8a53d6 20.21%,
		#b450b7 48.2%,
		#d54da0 78.64%,
		#f84a86 107.49%
	);

	width: -webkit-fit-content;

	width: -moz-fit-content;

	width: fit-content;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	background-size: 180%;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	white-space: nowrap;
	-webkit-animation: text-animate 3s linear infinite alternate;
	        animation: text-animate 3s linear infinite alternate;
}
.gradient-bg {
	background: linear-gradient(
		90deg,
		#fd4982 0%,
		#e84b92 12.57%,
		#ba4fb2 41.7%,
		#a950bf 56.38%,
		#9352cf 69.26%,
		#5b55f7 95.97%,
		#5256fe 100.02%
	);
	border-radius: 2.5rem 0rem 2.5rem 0rem;
	padding: 0rem 1.2rem;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	background-size: 200%;
	-webkit-animation: text-animate 3s linear infinite alternate;
	        animation: text-animate 3s linear infinite alternate;
}
@-webkit-keyframes text-animate {
	0% {
		background-position: left;
	}

	100% {
		background-position: right;
	}
}
@keyframes text-animate {
	0% {
		background-position: left;
	}

	100% {
		background-position: right;
	}
}
#text-white {
	color: #ffffff;
}
/* @media only screen and (max-width: 768px) {
	.aos-animate {
		-o-transition-property: none !important;
		-moz-transition-property: none !important;
		-ms-transition-property: none !important;
		-webkit-transition-property: none !important;
		transition-property: none !important;

		-o-transform: none !important;
		-moz-transform: none !important;
		-ms-transform: none !important;
		-webkit-transform: none !important;
		transform: none !important;

		-webkit-animation: none !important;
		-moz-animation: none !important;
		-o-animation: none !important;
		-ms-animation: none !important;
		animation: none !important;
	}
} */
.card-skeleton {
	background-color: #1d1a27;
	border-radius: 3.2rem;
	padding: 2rem 2rem;
	opacity: 0.8;
	width: auto;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
}
.card-skeleton .skeleton-img {
	border-radius: 100rem;
	width: 100%;
	margin-top: 1.5rem;
	text-align: center;
	margin-bottom: 1rem;
}
.card-skeleton .skeleton-text {
	margin: 1rem 0rem;
}


/* banner */
.main {
	min-height: 100vh;
}
.banner-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-top: 10rem;
}
.banner-left {
	text-align: left;
}
.banner-right {
	text-align: right;
	position: relative;
	left: 0;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}
.banner-left h1 {
	font-size: 12.8rem;
	font-weight: 800;
}
#welcome {
	font-size: 3.6rem;
}
#chapter {
	font-size: 3rem;
}
#full-form {
	font-size: 1.5rem;
	position: relative;
	top: -2rem;
	font-weight: 400;
}
#club-desc {
	font-size: 2rem;
}
.left-margin {
	margin-left: 0.8rem;
}

/* number */
.number-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	margin-top: 20rem;
	text-align: center;
	width: 100%;
	background-color: #1d1a27;
	border-radius: 5.8rem;
}
.number {
	margin: 4rem 0rem;
	border-right: 1px solid #9e418c;
}
.noborder {
	border-right: 1px solid #9e418c;
}
.number-no {
	font-size: 3.5rem;
	font-weight: 600;
}
.number-text {
	font-size: 2.4rem;
	font-weight: 300;
}
/* about */
.about-us-container {
	margin-top: 10rem;
	text-align: right;
}
.about-us-container h1 {
	font-size: 4.8rem;
	font-weight: 500;
}
.about-left {
	margin-top: 6rem;
	text-align: left;
}
.questions .about-left h2 {
	color: #fd4982;
	font-size: 3.2rem;
	font-weight: 500;
}
.questions .about-left p {
	margin-top: 3rem;
	color: #ffffff;
	font-size: 1.6rem;
	font-weight: 400;
	inline-size: 80rem;
	line-height: 2.2rem;
}
.questions .about-right h2 {
	color: #6591e1;
	font-size: 3.2rem;
	font-weight: 500;
}
.questions .about-right p {
	margin-top: 3rem;
	color: #ffffff;
	font-size: 1.6rem;
	font-weight: 400;
	inline-size: 80rem;
	float: right;
}
.clear {
	clear: both;
}
.domains-container {
	margin-top: 15rem;
	margin-bottom: 15rem;
}
.domains-container h1 {
	margin-top: 10rem;
	font-size: 4.8rem;
	font-weight: 500;
}
.domains-container p {
	margin-top: 3rem;
	color: #ffffff;
	font-size: 1.6rem;
	font-weight: 400;
	inline-size: 80rem;
	line-height: 2.2rem;
	width: 100%;
}
.domain-card-col .card {
	text-align: center;
	background-color: #1d1a27;
	min-width: 20rem;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 3rem;
	border-radius: 1rem;
	min-height: 20rem;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
}

.domain-card-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	margin: 0 auto;
	grid-gap: 5rem;
	gap: 5rem;
	margin-top: 8rem;
}
/* contact */
.contact-container {
	margin-top: 15rem;
	margin-bottom: 15rem;
	text-align: right;
}
.contact-container h1 {
	font-size: 4.8rem;
	font-weight: 500;
	margin-bottom: 4.8rem;
}
.contact-div .left input {
	display: block;
	background-color: #1d1a27;
	border: none;
	border-radius: 0.4rem;
	padding: 0.5rem 1rem;
	margin: 1rem;
	width: 80%;
	color: #ffffff;
}
.contact-div .right textarea {
	display: block;
	background-color: #1d1a27;
	border: none;
	border-radius: 0.4rem;
	resize: none;
	color: #ffffff;
	padding: 0.5rem 1rem;
}
.contact-div {
	display: grid;
	grid-template-columns: 1fr 1fr;
	text-align: left;
	width: -webkit-max-content;
	width: max-content;
	grid-gap: 0rem;
	gap: 0rem;
	margin: 0 auto;
}
.icon-div p {
	display: inline;
	font-size: 1.5rem;
}
.icon-div .icon {
	color: #a950bf;
	font-size: 2.4rem;
	padding-top: 0.5rem;
	margin-right: 1rem;
}
.contact-button {
	position: relative;
	left: 40rem;
}

.contact-button:hover {
	-webkit-transform: translateY(-3px);
	        transform: translateY(-3px);
	box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	-webkit-filter: brightness(2) drop-shadow(0 0 0.75rem #a950bf);
	        filter: brightness(2) drop-shadow(0 0 0.75rem #a950bf);
	transition: all 0.3s ease-in-out;
}
.contact-button:active {
	-webkit-transform: translateY(-1px);
	        transform: translateY(-1px);
	box-shadow: 0.5rem 1rem rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	-webkit-filter: brightness(2) drop-shadow(0 0 2rem #a950bf);
	        filter: brightness(2) drop-shadow(0 0 2rem #a950bf);
	transition: all 0.1s ease-in-out;
}
.contact-button::after {
	content: "";
	display: inline-block;
	height: 100%;
	width: 100%;
	border-radius: 100px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	transition: all 0.3s ease-in-out;
}
.contact-button:hover::after {
	-webkit-transform: scaleX(1.4) scaleY(1.6);
	        transform: scaleX(1.4) scaleY(1.6);
	opacity: 0;
}
/* media query */
@media screen and (max-width: 1000px) {
	.banner-container {
		grid-template-columns: 1fr;
		position: relative;
		min-height: 100vh;
	}
	.banner-container > .banner-right {
		position: relative;
		top: 25%;
	}
	.domain-card-container {
		grid-template-columns: 1fr 1fr 1fr;
	}
	.questions .about-left p {
		width: 80vw;
		word-wrap: break-word;
	}
	.questions .about-right p {
		width: 80vw;
		word-wrap: break-word;
	}
	.questions .about-right {
		margin-top: 8rem;
	}
	.contact-div {
		grid-template-columns: 1fr;
		position: relative;
		width: 90vw;
	}
	.contact-container > .contact-div > form > input {
		width: 80%;
		padding: 1rem;
		margin: 2rem auto;
		background-color: #1d1a27;
	}
	.contact-div .right textarea {
		width: 80%;
		padding: 1rem;
		margin: 2rem auto;
	}
	.contact-div .icons {
		position: absolute;
		bottom: -10rem;
		left: 50%;
		-webkit-transform: translateX(-50%);
		        transform: translateX(-50%);
	}
	.contact-button {
		position: relative;
		left: 25rem;
	}
	.contact-div .icons {
		position: relative;
		left: 0;
		bottom: -42rem;
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
	.contact-div .right {
		position: relative;
		top: -7rem;
		left: -3rem;
	}
}
@media screen and (max-width: 830px) {
	.number-container {
		grid-template-columns: 1fr 1fr;
	}
	.nob {
		border: none;
	}
	.domain-card-container {
		grid-template-columns: 1fr 1fr;
	}

	.center-card {
		position: relative;
		left: 65%;
	}
}
.banner-right {
	-webkit-transform: scale(0.8);
	        transform: scale(0.8);
	position: relative;
	left: 40%;
	top: 10%;
}

.cube {
	position: relative;
	width: 300px;
	height: 300px;
	-webkit-transform-style: preserve-3d;
	        transform-style: preserve-3d;

	-webkit-animation: animate 15s linear infinite;

	        animation: animate 15s linear infinite;
}
@-webkit-keyframes animate {
	0% {
		-webkit-transform: rotateX(-30deg) rotateY(0);
		        transform: rotateX(-30deg) rotateY(0);
	}
	50% {
		-webkit-transform: rotateX(-90deg) rotateY(180deg);
		        transform: rotateX(-90deg) rotateY(180deg);
	}
	100% {
		-webkit-transform: rotateX(-30deg) rotateY(360deg);
		        transform: rotateX(-30deg) rotateY(360deg);
	}
}
@keyframes animate {
	0% {
		-webkit-transform: rotateX(-30deg) rotateY(0);
		        transform: rotateX(-30deg) rotateY(0);
	}
	50% {
		-webkit-transform: rotateX(-90deg) rotateY(180deg);
		        transform: rotateX(-90deg) rotateY(180deg);
	}
	100% {
		-webkit-transform: rotateX(-30deg) rotateY(360deg);
		        transform: rotateX(-30deg) rotateY(360deg);
	}
}

.cube div {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-transform-style: preserve-3d;
	        transform-style: preserve-3d;
}

.cube div span {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	will-change: transform;
	background: linear-gradient(180deg, #1e1e1e 30%, #262746 100.02%);
	-webkit-transform: rotateY(calc(90deg * var(--i))) translateZ(150px);
	        transform: rotateY(calc(90deg * var(--i))) translateZ(150px);
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-transform-style: preserve-3d;
	        transform-style: preserve-3d;
}
.cube div span h2 {
	position: absolute;
	font-size: 10em;
	color: white;
	will-change: transform;
	-webkit-transform: translateZ(50px);
	        transform: translateZ(50px);
}
.cube div span h2:nth-child(1) {
	will-change: transform;
	-webkit-transform: translateZ(0) translateY(20px);
	        transform: translateZ(0) translateY(20px);
	color: rgba(0, 0, 0, 0.1);
	-webkit-filter: blur(2px);
	        filter: blur(2px);
}
.top {
	will-change: transform;
	position: absolute;
	top: 0;
	left: 0;
	width: 300px;
	height: 300px;
	background: #222;
	-webkit-transform: rotateX(90deg) translateZ(150px);
	        transform: rotateX(90deg) translateZ(150px);
}
.top::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 300px;
	height: 300px;
	will-change: transform;
	background: linear-gradient(90deg, #5256fe 100.02%);
	-webkit-transform: translateZ(-380px);
	        transform: translateZ(-380px);
	-webkit-filter: blur(20px);
	        filter: blur(20px);
	box-shadow: 0 0 120px #5256fe, 0 0 200px #9352cf, 0 0 300px #ba4fb2,
		0 0 400px #e84b92, 0 0 500px #fd4982;
}
.top img {
	position: absolute;
	top: 50%;
	left: 50%;
	will-change: transform;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}
@media screen and (max-width: 450px) {
	.number {
		margin: 2.5rem 0rem;
		border-right: 1px solid #9e418c;
	}
	.number-no {
		font-size: 3rem;
		font-weight: 600;
	}
	.number-text {
		font-size: 2rem;
		font-weight: 300;
	}
}
@media screen and (max-width: 540px) {
	.domain-card-col .card {
		min-width: 18rem;
		min-height: 15rem;
		margin: auto;
	}
	.banner-right {
		-webkit-transform: scale(0.7);
		        transform: scale(0.7);
		position: absolute;
		left: 30%;

		top: 0;
	}
	.domain-card-container .domain-card-col {
		grid-gap: 0rem;
		gap: 0rem;
	}
	.domain-card-container .domain-card-col .card {
		padding: 1rem;
	}
	.domain-card-container .domain-card-col .card img {
		margin-top: 1.5rem;
		width: 50px;
	}
}
@media screen and (max-width: 500px) {
	.domain-card-col .card {
		min-width: 15rem;
		min-height: 15rem;
		margin: auto;
	}

	.domain-card-container .domain-card-col {
		grid-gap: 0rem;
		gap: 0rem;
	}
	.domain-card-container .domain-card-col .card {
		padding: 1rem;
	}
	.domain-card-container .domain-card-col .card img {
		margin-top: 1.5rem;
		width: 40px;
	}
	.domain-card-container .domain-card-col .card p {
		font-size: 1rem;
	}
}
@media screen and (max-width: 412px) {
	.domain-card-col .card {
		min-width: 12rem;
		min-height: 12rem;
		margin: auto;
	}

	.domain-card-container .domain-card-col {
		grid-gap: 0rem;
		gap: 0rem;
	}
	.domain-card-container .domain-card-col .card {
		padding: 1rem;
	}
	.domain-card-container .domain-card-col .card img {
		margin-top: 1.5rem;
		width: 30px;
	}
	.domain-card-container .domain-card-col .card p {
		font-size: 1rem;
	}
}
@media screen and (max-width: 400px) {
	.banner-right {
		-webkit-transform: scale(0.6);
		        transform: scale(0.6);
		position: absolute;
		left: 0%;

		width: -webkit-min-content;

		width: min-content;
	}
}
@media (hover: hover) {
	.domain-card-col .card:hover {
		text-align: center;
		background-color: #1d1a27;
		-webkit-transform: scale(1.1);
		        transform: scale(1.1);
		-webkit-filter: brightness(1.5) drop-shadow(0 0rem 15rem #a950bf);
		        filter: brightness(1.5) drop-shadow(0 0rem 15rem #a950bf);
		background-color: #230a41;
		border-radius: 1rem;
	}
	.domain-card-col .card:active {
		text-align: center;
		background-color: #230a41;
		-webkit-transform: scale(1);
		        transform: scale(1);
		-webkit-filter: brightness(1.6) drop-shadow(0 0 12rem #a950bf);
		        filter: brightness(1.6) drop-shadow(0 0 12rem #a950bf);
		border-radius: 1rem;
	}
}
#q2 {
	display: block;
}

.navbar {
	width: 100vw;
	margin: 0 auto;
	height: 8.25em;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 1rem;
	position: fixed;
	top: 0;
	left: 0;
	background-color: transparent;
	z-index: 2;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
}

.navbar.active {
	background-image: radial-gradient(
		circle 1500px at 2.7% 11%,
		#3c3c5f 9.37%,
		#4c3869 20.21%,
		#522553 98.2%
	);
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	z-index: 2;
}
.navbar ul,
.navbar li {
	list-style: none;
	text-decoration: none;
}

.nav-menu {
	display: flex;
}
.navbar img {
	width: 90px;
}

.nav-item {
	padding: 0rem 3rem;
	font-size: 1.5rem;
}
.nav-logo {
	margin-right: auto;
	width: 20%;
}

.hamburger {
	display: none;
}

.icon-hamburger {
	font-size: 4rem;
	padding: 0rem 1rem;
}
.nav-item {
	text-decoration: none;
	color: #ffffff;
	padding: 0.25rem 1.2rem;
	margin: 1rem;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
}
.navbar > ul > .nav-item > .nav-links {
	color: #ffffff;
	list-style: none;
	text-decoration: none;
	font-weight: 500;
	font-size: 2rem;
}

@media (hover) {
	.navbar > ul > .nav-item > .nav-links:hover {
		background: linear-gradient(
			45deg,
			#fd4982 0%,
			#e84b92 50.57%,
			#a950bf 73.37%,
			#9352cf 80.25%,
			#5b55f7 95.95%,
			#5256fe 100%
		);
		background-clip: text;
		-webkit-background-clip: text;
		color: transparent;
		transition: all 0.3s ease;
	}
	.nav-links:active {
		background: linear-gradient(
			45deg,
			#fd4982 0%,
			#e84b92 50.57%,
			#a950bf 73.37%,
			#9352cf 80.25%,
			#5b55f7 95.95%,
			#5256fe 100%
		);
		background-clip: text;
		-webkit-background-clip: text;
		color: transparent;
	}
}

@media screen and (max-width: 968px) {
	.nav-menu {
		position: fixed;
		top: 0;
		right: -100%;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		width: 60%;
		background-image: radial-gradient(
			circle 1500px at 2.7% 11%,
			#3c3c5f 9.37%,
			#4c3869 20.21%,
			#522553 98.2%
		);
		text-align: center;
		transition: all 0.3s ease;
		z-index: 0;
	}
	.navbar img {
		width: 80%;
	}

	.nav-menu.active {
		top: 0;
		right: 0;
	}

	.nav-item {
		margin: 1.5rem;
		font-size: 1.8rem;
		justify-content: center;
	}

	.hamburger {
		display: flex;
	}

	.icon-hamburger {
		z-index: 99999;
	}
	.nav-logo {
		margin-right: auto;
		max-width: 20%;
		padding-top: 4rem;
	}
}
@media screen and (max-width: 750px) {
	.nav-logo {
		margin-right: auto;
		max-width: 30%;
		padding-top: 3rem;
	}
}
.stop-scrolling {
	height: 100%;
	overflow: hidden;
}
.nav-item {
	text-decoration: none;
	color: #ffffff;
	padding: 0.25rem 1.2rem;
	margin: 1rem;
	transition: all 0.3s ease;
}
.links:active {
	border-bottom: 1pc solid var(--primary);
}

.event-landing {
	min-height: 100vh;
}

.event-main {
	margin-top: 10rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 5rem;
	gap: 5rem;
	/* gap: 15rem; */
}
.event-main-right {
	text-align: center;
}
.event-main-left h1 {
	font-size: 4.8rem;
	margin-left: 4rem;
}

.event-desc {
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 2.2rem;
	opacity: 0.7;
	margin-top: 4rem;
}
.noborder {
	border-right: 1px solid #9e418c;
}
.nob {
	border-right: none;
}
/* Carousel */
.carousel {
	max-width: -webkit-fit-content;
	max-width: -moz-fit-content;
	max-width: fit-content;
	margin: 10rem 0rem;
}
.carousel-img {
	width: 40rem;
	margin: 0 auto;
}
@media screen and (max-width: 1050px) {
	.event-main {
		margin-top: 10rem;
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 5rem;
		gap: 5rem;
		/* gap: 15rem; */
	}
}
@media screen and (max-width: 768px) {
	.noborder {
		border-right: none;
	}
}
@media screen and (max-width: 555px) {
	.event-main {
		margin-top: 10rem;
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 5rem;
		gap: 5rem;
		/* gap: 15rem; */
	}
	.event-main-left h1 {
		font-size: 4rem;
		margin-left: 4rem;
	}
	#noborder {
		border-right: none;
	}
	.event-desc {
		font-size: 1.4rem;
		font-weight: 400;
		line-height: 2.2rem;
		opacity: 0.7;
		margin-top: 4rem;
	}
}
@media screen and (max-width: 450px) {
	.number {
		margin: 2.5rem 0rem;
	}
	.number-no {
		font-size: 2.5rem;
		font-weight: 600;
	}
	.number-text {
		font-size: 1.2rem;
		font-weight: 300;
	}
}

.counter {
	background-color: #1d1a27;
	width: -webkit-max-content;
	width: max-content;
	margin: 0 auto;
	padding: 4rem;
	border-radius: 2rem;
}

.register-button {
	background-color: #a950bf;
	color: #fff;
	padding: 0.75rem 1.5rem;
	border-radius: 0.8rem;
	border: none;
	margin-top: 4rem;
}
.register-button a{
	color: #fff;
	text-decoration: none;
}
.register-button:link,
.register-button:visited {
	text-decoration: none;
	text-transform: uppercase;
	padding: 1.5rem 4rem;
	display: inline-block;
	border-radius: 10rem;
	position: relative;
	-webkit-animation: slideInUp 1s ease-in-out;
	        animation: slideInUp 1s ease-in-out;
	
	
}
.register-button:hover {
	-webkit-transform: translateY(-3px);
	        transform: translateY(-3px);
	box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	-webkit-filter: brightness(2) drop-shadow(0 0 0.75rem #a950bf);
	        filter: brightness(2) drop-shadow(0 0 0.75rem #a950bf);
	transition: all 0.3s ease-in-out;
}
.register-button:active {
	-webkit-transform: translateY(-1px);
	        transform: translateY(-1px);
	box-shadow: 0.5rem 1rem rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	-webkit-filter: brightness(2) drop-shadow(0 0 2rem #a950bf);
	        filter: brightness(2) drop-shadow(0 0 2rem #a950bf);
	transition: all 0.1s ease-in-out;
}
.register-button::after {
	content: "";
	display: inline-block;
	height: 100%;
	width: 100%;
	border-radius: 100px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	transition: all 0.3s ease-in-out;
}
.register-button:hover::after {
	-webkit-transform: scaleX(1.4) scaleY(1.6);
	        transform: scaleX(1.4) scaleY(1.6);
	opacity: 0;
}
.counter-timer {
	display: flex;
}
.counter-timer > .timer-field {
	font-size: 2rem;
	border-right: 1px solid #9e418c;
	padding: 0rem 2rem;
	margin-top: 2.5rem;
}
@media screen and (max-width: 555px) {
	.counter-timer > .timer-field {
		font-size: 1.7rem;
		border-right: 1px solid #9e418c;
		padding: 0rem 1.5rem;
		margin-top: 2.5rem;
	}
	.counter {
		background-color: #1d1a27;
		width: -webkit-max-content;
		width: max-content;
		margin: 0 auto;
		padding: 2.5rem;
		border-radius: 2rem;
	}

	.register-button {
		background-color: #a950bf;
		color: #fff;
		padding: 0.75rem 1.5rem;
		border-radius: 0.8rem;
		border: none;
		margin-top: 4rem;
	}
}
@media screen and (max-width: 455px) {
	.counter-timer > .timer-field {
		font-size: 1.5rem;
		border-right: 1px solid #9e418c;
		padding: 0rem 1.2rem;
		margin-top: 2.5rem;
	}
	.counter {
		background-color: #1d1a27;
		width: -webkit-max-content;
		width: max-content;
		margin: 0 auto;
		padding: 2.1rem;
		border-radius: 2rem;
	}

	.register-button {
		background-color: #a950bf;
		color: #fff;
		padding: 0.75rem 1.5rem;
		border-radius: 0.8rem;
		border: none;
		margin-top: 4rem;
	}
}

.acumen-landing {
	display: grid;
	grid-template-columns: 1fr 1fr;
	min-height: 50vh;
	margin-top: 10rem;
}
.acumen-img {
	width: 65%;
}

.acumen-right {
	text-align: right;
	margin-top: 6rem;
}
.acumen-right h1 {
	font-size: 9.6rem;
	background: linear-gradient(
		262.63deg,
		#235876 -9.37%,
		#4ebc96 45.4%,
		#aeedba 107.49%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	background-size: 300%;
	-webkit-animation: text-animate 3s linear infinite alternate;
	        animation: text-animate 3s linear infinite alternate;
}
.acumen-desc1 {
	font-size: 2rem;
	opacity: 0.8;
	letter-spacing: 0.085em;
	line-height: 2.7rem;
}
.acumen-desc2 {
	margin-top: 4rem;
	font-size: 1.6rem;
	opacity: 0.8;
	letter-spacing: 0.085em;
	line-height: 2.6rem;
}
.acumen-cards-row {
	height: -webkit-max-content;
	height: max-content;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
}
.acumen-card {
	border-top: 4px solid;
	border-image: linear-gradient(
		90deg,
		#fd4982 0%,
		#e84b92 12.57%,
		#ba4fb2 41.69%,
		#a950bf 56.37%,
		#9352cf 69.25%,
		#5b55f7 95.95%,
		#5256fe 100%
	);
	border-image-slice: 1;
	position: relative;
	z-index: 1;
	margin-left: 3rem;
	width: 80%;
	padding: 3rem;
	margin: 0 auto;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	text-align: center;
}
.acumen-card h2 {
	font-size: 2.4rem;
	font-weight: 700;
	font-size: 2.4rem;
	line-height: 3.3rem;
}
.acumen-card p {
	font-weight: 400;
	font-size: 1.6rem;
	line-height: 2.4rem;
	margin-top: 2rem;
	letter-spacing: 0.03em;
}

.acumen-events-section h1 {
	text-align: center;
	margin-bottom: 8rem;
	font-size: 2.4rem;
}
/* media query */
@media screen and (max-width: 1300px) {
	.acumen-cards-row {
		grid-template-columns: 1fr 1fr;
		grid-gap: 2rem;
		gap: 2rem;
	}
}

@media screen and (max-width: 500px) {
	.acumen-landing .acumen-right h1 {
		font-size: 7rem;
	}
	.acumen-landing .acumen-right p {
		font-size: 1.5rem;
	}
	.acumen-right {
		width: 80vw;
		margin: 0 auto;
	}
}
@media screen and (max-width: 380px) {
	.acumen-landing .acumen-right h1 {
		font-size: 6rem;
	}
	.acumen-landing .acumen-right p {
		font-size: 1.2rem;
	}
	.acumen-right {
		width: 80vw;
		margin: 0 auto;
	}
}
@media screen and (max-width: 380px) {
	.acumen-landing .acumen-right h1 {
		font-size: 5rem;
	}
}
@media screen and (max-width: 768px) {
	.acumen-landing {
		position: relative;
		left: 20rem;
		display: grid;
		grid-template-columns: 1fr;
		grid-auto-flow: column;
		text-align: center;
	}

	.acumen-cards-row {
		grid-template-columns: 1fr;
		grid-row-gap: 2rem;
		row-gap: 2rem;
	}
	.acumen-img {
		display: none;
	}
}
@media screen and (max-width: 724px) {
	.acumen-landing {
		position: relative;
		left: 0rem;
	}
}
.acumen {
	overflow: none;
}
@media (hover: hover) {
	.acumen-card:hover {
		-webkit-transform: scale(1.1);
		        transform: scale(1.1);
		-webkit-filter: drop-shadow(0 0rem 15rem #a950bf);
		        filter: drop-shadow(0 0rem 15rem #a950bf);
		transition: all 0.3s ease-in-out;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
	}
	.acumen-card:hover::after {
		-webkit-transform: scaleY(0.96);
		        transform: scaleY(0.96);
		-webkit-transform-origin: top;
		        transform-origin: top;
		transition: all 0.3s ease-in-out;
		-webkit-filter: drop-shadow(0 0rem 15rem #a950bf);
		        filter: drop-shadow(0 0rem 15rem #a950bf);
	}
	.acumen-card:active {
		-webkit-transform: scaleY(0.96);
		        transform: scaleY(0.96);
		-webkit-filter: drop-shadow(0 0rem 8rem #a950bf);
		        filter: drop-shadow(0 0rem 8rem #a950bf);
		transition: all 0.3s ease-in-out;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
	}
	.acumen-card::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background: linear-gradient(
			90deg,
			#fd49821c 0%,
			#e84b9228 12.57%,
			#ba4fb32d 41.69%,
			#a950bf1f 56.37%,
			#9352cf25 69.25%,
			#5a55f723 95.95%,
			#5255fe21 100%
		);

		top: 0;
		left: 0;
		z-index: -1;
		-webkit-transform: scaleY(0);
		        transform: scaleY(0);
		-webkit-transform-origin: top;
		        transform-origin: top;
		-webkit-filter: drop-shadow(0 0rem 8rem #a950bf);
		        filter: drop-shadow(0 0rem 8rem #a950bf);
		transition: all 0.3s ease-in-out;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
	}
}

.members-container h1 {
	font-size: 4.8rem;
}
.members-container {
	margin-top: 15rem;
}
.board-img-container img {
	border-radius: 100rem;
	width: 10rem;
	margin-top: 1.5rem;
}
.board-card {
	text-align: center;
}
.board-name {
	font-size: 2rem;
	font-weight: 600;
	margin-top: 1rem;
}
.board-post {
	opacity: 0.6;
	font-size: 1.5rem;
	margin-top: 1rem;
	margin-bottom: 1.2rem;
}
.board-link-icon {
	color: #ffffff;
	font-size: 2rem;
	margin: 0rem 0.5rem;
	opacity: 0.6;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
}

.board-card {
	background-color: #1d1a27;
	border-radius: 3.2rem;
	padding: 2rem 0rem;
	opacity: 0.8;
	width: auto;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
}

.board-card-row {
	margin-top: 10rem;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;

	grid-gap: 5rem;

	gap: 5rem;
}
.members-links ul,
li {
	list-style-type: none;
}
.member-category {
	color: #ffffff;
	text-decoration: none;
	font-size: 2rem;
	font-weight: 500;
}
.members-links ul {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}
.members-links {
	margin: 6rem 0rem;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
}
.members-links > ul > li {
	color: #ffffff;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
}

.mancomm {
	margin-top: 10rem;
	font-size: 5rem;
}
/* media query */
@media screen and (max-width: 1000px) {
	.board-card-row {
		margin-top: 10rem;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;

		grid-gap: 5rem;

		gap: 5rem;
	}
}
@media screen and (max-width: 768px) {
	.board-card-row {
		margin-top: 10rem;
		display: grid;
		grid-template-columns: 1fr 1fr;

		grid-gap: 5rem;

		gap: 5rem;
	}
}
@media screen and (max-width: 600px) {
	.members-links ul li {
		text-align: center;
		margin-top: 3rem;
		font-size: 1.5rem;
	}
}
@media screen and (max-width: 500px) {
	.board-card-row {
		margin-top: 10rem;
		display: grid;
		grid-template-columns: 1fr;

		grid-gap: 5rem;

		gap: 5rem;
	}
	.members-links ul li {
		padding: 0rem 2rem;
	}
	.members-container h1 {
		font-size: 4rem;
	}
}
@media screen and (max-width: 768px) {
	.board-link-icon {
		opacity: 1;
	}
}

@media (hover: hover) {
	.board-card:hover {
		opacity: 1;
		background-color: #230a41;
		-webkit-transform: scale(1.1) translateY(-1.5rem);
		        transform: scale(1.1) translateY(-1.5rem);
		box-shadow: #ffffff 0px 0px 0px 0.2rem;
	}

	.board-card:active {
		-webkit-transform: scale(1) translateY(0rem);
		        transform: scale(1) translateY(0rem);
	}
	.board-link-icon:hover {
		color: #f84a86;
	}
	.board-card:hover .board-link-icon {
		opacity: 1;
	}
	.members-links > ul > li:hover {
		background: linear-gradient(
			262.63deg,
			#5656fb -9.37%,
			#8a53d6 20.21%,
			#b450b7 48.2%,
			#d54da0 78.64%,
			#f84a86 107.49%
		);

		width: -webkit-fit-content;

		width: -moz-fit-content;

		width: fit-content;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
	}
}

.footer-container {
	background-color: #020304;
	color: #ffffff;
	position: relative;
	left: 0%;
	-webkit-transform: translateX(-10.15%);
	        transform: translateX(-10.15%);
	width: 100vw;
	padding: 2rem;
	min-height: 30vh;
	display: grid;
	grid-template-columns: 0.5fr 1fr;
	grid-gap: 0rem;
	gap: 0rem;
	margin-top: 10rem;
	margin-bottom: 4rem;
	max-width: 100vw;
	overflow: hidden;
}
.footer-right {
	/* border-top: 4px solid #f14a8a; */
	border-radius: 0rem;
	width: 100%;
	margin-left: auto;
	margin-top: 3rem;
	position: relative;
}
.footer-right::after {
	content: "";
	border-top: 4px solid #f14a8a;
	border-radius: 0rem;
	width: 80%;
	margin-left: 20rem;
	position: absolute;
}
.footer-container > .footer-right > .footer-right-wrapper {
	width: 50%;
	margin-left: auto;
	overflow: none;
}
.text {
	font-size: 10rem;
	position: relative;
}
/* .text > .manipal {
	font-size: 3rem;
	font-weight: 400;
	position: absolute;
	top: 0;
	right: 0;
	transform: rotate(-90deg) translate(-80%, -270%);
} */
.text {
	display: flex;
}
.text > .manipal {
	font-size: 3rem;
	font-weight: 400;
	-webkit-transform: rotate(-90deg);
	        transform: rotate(-90deg);
	margin-top: 1rem;
	margin-left: 4rem;
}
.footer-right {
	text-align: center;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 0rem;
	gap: 0rem;
}
.socials {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: start;
}
.social-icon {
	font-size: 2rem;
	margin: 0rem 2rem;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
}
.social-icon:hover {
	color: #f14a8a;
}
.links-section {
	margin: 2rem 2rem;
}
.footer-links {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 0rem 10rem;
	gap: 0rem 10rem;
}
.footer-link {
	color: #f1f1f1cf;
	text-decoration: none;
	font-size: 1.5rem;
	font-weight: 400;
	transition: all 0.3s ease-in-out;

	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
}
.footer-link:hover {
	color: #f14a8a;
}
.copyright {
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	bottom: 0;
	display: block;
}
.footer-main {
	color: #ffffff;
	font-weight: 600;
	font-size: 2rem;
}
.footer-mobile {
	display: none;
}
#manipal {
	width: 10rem;
	margin-top: 2.5rem;
}
@media screen and (max-width: 1400px) {
	.footer-links {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 0rem 5rem;
		gap: 0rem 5rem;
	}
}
@media screen and (max-width: 1268px) {
	.footer-right-wrapper {
		margin-right: 10rem;
	}
	.footer-links {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 0rem 2rem;
		gap: 0rem 2rem;
	}
}
@media screen and (max-width: 1110px) {
	.footer-right-wrapper {
		margin-right: 40rem;
	}
	.footer-links {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 0rem 2rem;
		gap: 0rem 2rem;
	}
}

@media screen and (max-width: 1110px) {
	.footer-pc {
		display: none;
	}
	.footer-mobile {
		margin-top: 10rem;
		display: block;
		max-width: 100vw;
		overflow-x: hidden;
	}
	.footer-mobile-wrapper {
		border-top: #f14a8a 4px solid;
	}
	.footer-mobile-down {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
	.footer-mobile-right {
		text-align: center;
	}
	.socials {
		margin-top: 2rem;
		display: flex;

		justify-content: center;
		align-items: center;
	}
	.socials h1 {
		margin-bottom: 2rem;
	}
	.footer-mobile-down > .text {
		font-size: 5.6rem;
	}
	.socials > .social-icon {
		margin: 0rem 1rem;
	}
}
@media screen and (max-width: 768px) {
	.footer-mobile-down > .text {
		font-size: 4.6rem;
	}
	.footer-mobile-down > .text > .manipal {
		font-size: 2rem;
		margin-left: 1.5rem;
	}
}
@media screen and (max-width: 638px) {
	.footer-mobile-down > .text {
		font-size: 4rem;
	}
	.footer-mobile-down > .text > .manipal {
		font-size: 1.7rem;
		margin-left: 2rem;
	}
	.footer-main {
		font-size: 1.5rem;
	}
}
@media screen and (max-width: 600px) {
	.footer-mobile-down > .text {
		font-size: 3.5rem;
	}
	.footer-mobile-down > .text > .manipal {
		font-size: 1.7rem;
		margin-left: 2rem;
	}
	.footer-mobile-right h1 {
		font-size: 1.5rem;
	}
	.socials > .social-icon {
		font-size: 1.5rem;
		margin: 0rem 0rem;
	}
}
@media screen and (max-width: 490px) {
	.socials a {
		margin: 0;
		max-width: -webkit-fit-content;
		max-width: -moz-fit-content;
		max-width: fit-content;
	}
	.socials a .social-icon {
		font-size: 1.4rem;
	}
}
@media screen and (max-width: 475px) {
	.footer-mobile-right {
		position: relative;
		top: -20%;
	}
	.socials {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-row-gap: 1rem;
		row-gap: 1rem;
	}
	.socials a {
		margin: 0;
		max-width: -webkit-fit-content;
		max-width: -moz-fit-content;
		max-width: fit-content;
	}
	.socials a .social-icon {
		font-size: 1.4rem;
	}
}
@media screen and (max-width: 460px) {
	.footer-mobile-down > .text {
		font-size: 3.5rem;
	}
	.footer-mobile-down > .text > .manipal {
		font-size: 1.7rem;
		margin-left: 2rem;
	}
}
@media screen and (max-width: 415px) {
	.footer-mobile-down > .text {
		font-size: 3rem;
	}
	.footer-mobile-down > .text > .manipal {
		font-size: 1.2rem;
		margin-left: 2rem;
	}
	.socials > .social-icon {
		font-size: 1.2rem;
		margin: 0rem 1rem;
	}
}
a {
	text-decoration: none;
	color: #ffffff;
}

.members-list-card {
	text-align: center;
	background-color: #1d1a27;
	padding: 4rem 0rem;
	border-radius: 2rem;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 1rem;
}
h2 {
	display: inline;
	padding: 0;
	margin: 0;
}
@media (max-width: 768px) {
	.members-list-card {
		grid-template-columns: 1fr 1fr;
	}
	.name h2 {
		font-size: 1.2rem;
	}
}

.members-list-card {
	text-align: center;
	background-color: #1d1a27;
	padding: 4rem 0rem;
	border-radius: 2rem;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 1rem;
}
h2 {
	display: inline;
	padding: 0;
	margin: 0;
}
@media (max-width: 768px) {
	.members-list-card {
		grid-template-columns: 1fr 1fr;
	}
	.name h2 {
		font-size: 1.2rem;
	}
}

