/* banner */
.main {
	min-height: 100vh;
}
.banner-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-top: 10rem;
}
.banner-left {
	text-align: left;
}
.banner-right {
	text-align: right;
	position: relative;
	left: 0;
	width: fit-content;
}
.banner-left h1 {
	font-size: 12.8rem;
	font-weight: 800;
}
#welcome {
	font-size: 3.6rem;
}
#chapter {
	font-size: 3rem;
}
#full-form {
	font-size: 1.5rem;
	position: relative;
	top: -2rem;
	font-weight: 400;
}
#club-desc {
	font-size: 2rem;
}
.left-margin {
	margin-left: 0.8rem;
}

/* number */
.number-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	margin-top: 20rem;
	text-align: center;
	width: 100%;
	background-color: #1d1a27;
	border-radius: 5.8rem;
}
.number {
	margin: 4rem 0rem;
	border-right: 1px solid #9e418c;
}
.noborder {
	border-right: 1px solid #9e418c;
}
.number-no {
	font-size: 3.5rem;
	font-weight: 600;
}
.number-text {
	font-size: 2.4rem;
	font-weight: 300;
}
/* about */
.about-us-container {
	margin-top: 10rem;
	text-align: right;
}
.about-us-container h1 {
	font-size: 4.8rem;
	font-weight: 500;
}
.about-left {
	margin-top: 6rem;
	text-align: left;
}
.questions .about-left h2 {
	color: #fd4982;
	font-size: 3.2rem;
	font-weight: 500;
}
.questions .about-left p {
	margin-top: 3rem;
	color: #ffffff;
	font-size: 1.6rem;
	font-weight: 400;
	inline-size: 80rem;
	line-height: 2.2rem;
}
.questions .about-right h2 {
	color: #6591e1;
	font-size: 3.2rem;
	font-weight: 500;
}
.questions .about-right p {
	margin-top: 3rem;
	color: #ffffff;
	font-size: 1.6rem;
	font-weight: 400;
	inline-size: 80rem;
	float: right;
}
.clear {
	clear: both;
}
.domains-container {
	margin-top: 15rem;
	margin-bottom: 15rem;
}
.domains-container h1 {
	margin-top: 10rem;
	font-size: 4.8rem;
	font-weight: 500;
}
.domains-container p {
	margin-top: 3rem;
	color: #ffffff;
	font-size: 1.6rem;
	font-weight: 400;
	inline-size: 80rem;
	line-height: 2.2rem;
	width: 100%;
}
.domain-card-col .card {
	text-align: center;
	background-color: #1d1a27;
	min-width: 20rem;
	width: fit-content;
	padding: 3rem;
	border-radius: 1rem;
	min-height: 20rem;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
}

.domain-card-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	width: fit-content;
	margin: 0 auto;
	gap: 5rem;
	margin-top: 8rem;
}
/* contact */
.contact-container {
	margin-top: 15rem;
	margin-bottom: 15rem;
	text-align: right;
}
.contact-container h1 {
	font-size: 4.8rem;
	font-weight: 500;
	margin-bottom: 4.8rem;
}
.contact-div .left input {
	display: block;
	background-color: #1d1a27;
	border: none;
	border-radius: 0.4rem;
	padding: 0.5rem 1rem;
	margin: 1rem;
	width: 80%;
	color: #ffffff;
}
.contact-div .right textarea {
	display: block;
	background-color: #1d1a27;
	border: none;
	border-radius: 0.4rem;
	resize: none;
	color: #ffffff;
	padding: 0.5rem 1rem;
}
.contact-div {
	display: grid;
	grid-template-columns: 1fr 1fr;
	text-align: left;
	width: max-content;
	gap: 0rem;
	margin: 0 auto;
}
.icon-div p {
	display: inline;
	font-size: 1.5rem;
}
.icon-div .icon {
	color: #a950bf;
	font-size: 2.4rem;
	padding-top: 0.5rem;
	margin-right: 1rem;
}
.contact-button {
	position: relative;
	left: 40rem;
}

.contact-button:hover {
	transform: translateY(-3px);
	box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	filter: brightness(2) drop-shadow(0 0 0.75rem #a950bf);
	transition: all 0.3s ease-in-out;
}
.contact-button:active {
	transform: translateY(-1px);
	box-shadow: 0.5rem 1rem rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	filter: brightness(2) drop-shadow(0 0 2rem #a950bf);
	transition: all 0.1s ease-in-out;
}
.contact-button::after {
	content: "";
	display: inline-block;
	height: 100%;
	width: 100%;
	border-radius: 100px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	transition: all 0.3s ease-in-out;
}
.contact-button:hover::after {
	transform: scaleX(1.4) scaleY(1.6);
	opacity: 0;
}
/* media query */
@media screen and (max-width: 1000px) {
	.banner-container {
		grid-template-columns: 1fr;
		position: relative;
		min-height: 100vh;
	}
	.banner-container > .banner-right {
		position: relative;
		top: 25%;
	}
	.domain-card-container {
		grid-template-columns: 1fr 1fr 1fr;
	}
	.questions .about-left p {
		width: 80vw;
		word-wrap: break-word;
	}
	.questions .about-right p {
		width: 80vw;
		word-wrap: break-word;
	}
	.questions .about-right {
		margin-top: 8rem;
	}
	.contact-div {
		grid-template-columns: 1fr;
		position: relative;
		width: 90vw;
	}
	.contact-container > .contact-div > form > input {
		width: 80%;
		padding: 1rem;
		margin: 2rem auto;
		background-color: #1d1a27;
	}
	.contact-div .right textarea {
		width: 80%;
		padding: 1rem;
		margin: 2rem auto;
	}
	.contact-div .icons {
		position: absolute;
		bottom: -10rem;
		left: 50%;
		transform: translateX(-50%);
	}
	.contact-button {
		position: relative;
		left: 25rem;
	}
	.contact-div .icons {
		position: relative;
		left: 0;
		bottom: -42rem;
		transform: translateX(0);
	}
	.contact-div .right {
		position: relative;
		top: -7rem;
		left: -3rem;
	}
}
@media screen and (max-width: 830px) {
	.number-container {
		grid-template-columns: 1fr 1fr;
	}
	.nob {
		border: none;
	}
	.domain-card-container {
		grid-template-columns: 1fr 1fr;
	}

	.center-card {
		position: relative;
		left: 65%;
	}
}
.banner-right {
	transform: scale(0.8);
	position: relative;
	left: 40%;
	top: 10%;
}

.cube {
	position: relative;
	width: 300px;
	height: 300px;
	transform-style: preserve-3d;

	animation: animate 15s linear infinite;
}
@keyframes animate {
	0% {
		transform: rotateX(-30deg) rotateY(0);
	}
	50% {
		transform: rotateX(-90deg) rotateY(180deg);
	}
	100% {
		transform: rotateX(-30deg) rotateY(360deg);
	}
}

.cube div {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
}

.cube div span {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	will-change: transform;
	background: linear-gradient(180deg, #1e1e1e 30%, #262746 100.02%);
	transform: rotateY(calc(90deg * var(--i))) translateZ(150px);
	display: flex;
	justify-content: center;
	align-items: center;
	transform-style: preserve-3d;
}
.cube div span h2 {
	position: absolute;
	font-size: 10em;
	color: white;
	will-change: transform;
	transform: translateZ(50px);
}
.cube div span h2:nth-child(1) {
	will-change: transform;
	transform: translateZ(0) translateY(20px);
	color: rgba(0, 0, 0, 0.1);
	filter: blur(2px);
}
.top {
	will-change: transform;
	position: absolute;
	top: 0;
	left: 0;
	width: 300px;
	height: 300px;
	background: #222;
	transform: rotateX(90deg) translateZ(150px);
}
.top::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 300px;
	height: 300px;
	will-change: transform;
	background: linear-gradient(90deg, #5256fe 100.02%);
	transform: translateZ(-380px);
	filter: blur(20px);
	box-shadow: 0 0 120px #5256fe, 0 0 200px #9352cf, 0 0 300px #ba4fb2,
		0 0 400px #e84b92, 0 0 500px #fd4982;
}
.top img {
	position: absolute;
	top: 50%;
	left: 50%;
	will-change: transform;
	transform: translate(-50%, -50%);
}
@media screen and (max-width: 450px) {
	.number {
		margin: 2.5rem 0rem;
		border-right: 1px solid #9e418c;
	}
	.number-no {
		font-size: 3rem;
		font-weight: 600;
	}
	.number-text {
		font-size: 2rem;
		font-weight: 300;
	}
}
@media screen and (max-width: 540px) {
	.domain-card-col .card {
		min-width: 18rem;
		min-height: 15rem;
		margin: auto;
	}
	.banner-right {
		transform: scale(0.7);
		position: absolute;
		left: 30%;

		top: 0;
	}
	.domain-card-container .domain-card-col {
		gap: 0rem;
	}
	.domain-card-container .domain-card-col .card {
		padding: 1rem;
	}
	.domain-card-container .domain-card-col .card img {
		margin-top: 1.5rem;
		width: 50px;
	}
}
@media screen and (max-width: 500px) {
	.domain-card-col .card {
		min-width: 15rem;
		min-height: 15rem;
		margin: auto;
	}

	.domain-card-container .domain-card-col {
		gap: 0rem;
	}
	.domain-card-container .domain-card-col .card {
		padding: 1rem;
	}
	.domain-card-container .domain-card-col .card img {
		margin-top: 1.5rem;
		width: 40px;
	}
	.domain-card-container .domain-card-col .card p {
		font-size: 1rem;
	}
}
@media screen and (max-width: 412px) {
	.domain-card-col .card {
		min-width: 12rem;
		min-height: 12rem;
		margin: auto;
	}

	.domain-card-container .domain-card-col {
		gap: 0rem;
	}
	.domain-card-container .domain-card-col .card {
		padding: 1rem;
	}
	.domain-card-container .domain-card-col .card img {
		margin-top: 1.5rem;
		width: 30px;
	}
	.domain-card-container .domain-card-col .card p {
		font-size: 1rem;
	}
}
@media screen and (max-width: 400px) {
	.banner-right {
		transform: scale(0.6);
		position: absolute;
		left: 0%;

		width: min-content;
	}
}
@media (hover: hover) {
	.domain-card-col .card:hover {
		text-align: center;
		background-color: #1d1a27;
		transform: scale(1.1);
		filter: brightness(1.5) drop-shadow(0 0rem 15rem #a950bf);
		background-color: #230a41;
		border-radius: 1rem;
	}
	.domain-card-col .card:active {
		text-align: center;
		background-color: #230a41;
		transform: scale(1);
		filter: brightness(1.6) drop-shadow(0 0 12rem #a950bf);
		border-radius: 1rem;
	}
}
#q2 {
	display: block;
}
