.counter {
	background-color: #1d1a27;
	width: max-content;
	margin: 0 auto;
	padding: 4rem;
	border-radius: 2rem;
}

.register-button {
	background-color: #a950bf;
	color: #fff;
	padding: 0.75rem 1.5rem;
	border-radius: 0.8rem;
	border: none;
	margin-top: 4rem;
}
.register-button a{
	color: #fff;
	text-decoration: none;
}
.register-button:link,
.register-button:visited {
	text-decoration: none;
	text-transform: uppercase;
	padding: 1.5rem 4rem;
	display: inline-block;
	border-radius: 10rem;
	position: relative;
	animation: slideInUp 1s ease-in-out;
	
	
}
.register-button:hover {
	transform: translateY(-3px);
	box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	filter: brightness(2) drop-shadow(0 0 0.75rem #a950bf);
	transition: all 0.3s ease-in-out;
}
.register-button:active {
	transform: translateY(-1px);
	box-shadow: 0.5rem 1rem rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	filter: brightness(2) drop-shadow(0 0 2rem #a950bf);
	transition: all 0.1s ease-in-out;
}
.register-button::after {
	content: "";
	display: inline-block;
	height: 100%;
	width: 100%;
	border-radius: 100px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	transition: all 0.3s ease-in-out;
}
.register-button:hover::after {
	transform: scaleX(1.4) scaleY(1.6);
	opacity: 0;
}
.counter-timer {
	display: flex;
}
.counter-timer > .timer-field {
	font-size: 2rem;
	border-right: 1px solid #9e418c;
	padding: 0rem 2rem;
	margin-top: 2.5rem;
}
@media screen and (max-width: 555px) {
	.counter-timer > .timer-field {
		font-size: 1.7rem;
		border-right: 1px solid #9e418c;
		padding: 0rem 1.5rem;
		margin-top: 2.5rem;
	}
	.counter {
		background-color: #1d1a27;
		width: max-content;
		margin: 0 auto;
		padding: 2.5rem;
		border-radius: 2rem;
	}

	.register-button {
		background-color: #a950bf;
		color: #fff;
		padding: 0.75rem 1.5rem;
		border-radius: 0.8rem;
		border: none;
		margin-top: 4rem;
	}
}
@media screen and (max-width: 455px) {
	.counter-timer > .timer-field {
		font-size: 1.5rem;
		border-right: 1px solid #9e418c;
		padding: 0rem 1.2rem;
		margin-top: 2.5rem;
	}
	.counter {
		background-color: #1d1a27;
		width: max-content;
		margin: 0 auto;
		padding: 2.1rem;
		border-radius: 2rem;
	}

	.register-button {
		background-color: #a950bf;
		color: #fff;
		padding: 0.75rem 1.5rem;
		border-radius: 0.8rem;
		border: none;
		margin-top: 4rem;
	}
}
